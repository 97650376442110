import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

const GameContainer = styled.div`
  font-family: monospace;
  background-color: #000;
  color: #fff;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(32, 1fr);
  grid-template-rows: repeat(20, 1fr);
  gap: 1px;
  background-color: #000;
  border: 1px solid #fff;
  width: 90vw;
  height: 80vh;
`;

const Cell = styled.div`
  background-color: ${props => {
    if (props.isShip) return '#00ff00';
    if (props.isGoal) return '#ffff00';
    if (props.filled) return '#ff0000';
    return 'transparent';
  }};
  border: 1px solid #333;
`;

const Message = styled.div`
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
`;

const Instructions = styled.div`
  font-size: 24px;
  text-align: center;
  white-space: pre-line;
  line-height: 1.5;
`;

const Game = () => {
  const [grid, setGrid] = useState([]);
  const [shipPosition, setShipPosition] = useState({ x: 0, y: 0 });
  const [gameState, setGameState] = useState('instructions');
  const [countdown, setCountdown] = useState(3);

  const initializeGrid = useCallback(() => {
    const newGrid = [];
    for (let x = 0; x < 32; x++) {
      const column = new Array(20).fill(false);
      if (x > 0) { // Skip the first column
        const filledIndex = Math.floor(Math.random() * 20);
        column[filledIndex] = true;
      }
      newGrid.push(column);
    }
    setGrid(newGrid);
  }, []);

  const checkCollision = useCallback((x, y) => {
    return grid[x] && grid[x][y];
  }, [grid]);

  const checkWin = useCallback((x, y) => {
    return x === 31 && y === 19;
  }, []);

  const moveShip = useCallback((direction) => {
    if (gameState === 'playing') {
      setShipPosition(prev => {
        let newX = prev.x;
        if (direction === 'left' && prev.x > 0) {
          newX = prev.x - 1;
        } else if (direction === 'right' && prev.x < 31) {
          newX = prev.x + 1;
        }
        if (checkCollision(newX, prev.y)) {
          setGameState('gameover');
          return prev;
        }
        if (checkWin(newX, prev.y)) {
          setGameState('win');
        }
        return { ...prev, x: newX };
      });
    }
  }, [gameState, checkCollision, checkWin]);

  const startGame = useCallback(() => {
    initializeGrid();
    setShipPosition({ x: 0, y: 0 });
    setGameState('countdown');
    setCountdown(3);
    const timer = setInterval(() => {
      setCountdown(prev => {
        if (prev === 1) {
          clearInterval(timer);
          setGameState('playing');
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  }, [initializeGrid]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (gameState === 'instructions') {
        startGame();
      } else if (gameState === 'playing') {
        if (event.key === 'q' || event.key === 'Q') {
          moveShip('left');
        } else if (event.key === 'e' || event.key === 'E') {
          moveShip('right');
        }
      } else if (gameState === 'gameover' || gameState === 'win') {
        startGame();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [gameState, moveShip, startGame]);

  useEffect(() => {
    if (gameState === 'playing') {
      const fallInterval = setInterval(() => {
        setShipPosition(prev => {
          const newY = (prev.y + 1) % 20;
          if (checkCollision(prev.x, newY)) {
            clearInterval(fallInterval);
            setGameState('gameover');
            return prev;
          }
          if (checkWin(prev.x, newY)) {
            clearInterval(fallInterval);
            setGameState('win');
          }
          return { ...prev, y: newY };
        });
      }, 100); // Changed to 100ms (0.1 seconds)

      return () => clearInterval(fallInterval);
    }
  }, [gameState, checkCollision, checkWin]);

  if (gameState === 'instructions') {
    return (
      <GameContainer>
        <Instructions>
          Welcome to the VIC-20 Ship Game!
          {'\n\n'}
          Instructions:
          {'\n'}
          - Use 'Q' to move left
          {'\n'}
          - Use 'E' to move right
          {'\n'}
          - Avoid red blocks
          {'\n'}
          - Reach the yellow block to win
          {'\n\n'}
          Press any key to start!
        </Instructions>
      </GameContainer>
    );
  }

  return (
    <GameContainer>
      <Grid>
        {[...Array(20)].map((_, y) =>
          [...Array(32)].map((_, x) => (
            <Cell 
              key={`${x}-${y}`} 
              filled={grid[x] && grid[x][y]}
              isShip={x === shipPosition.x && y === shipPosition.y}
              isGoal={x === 31 && y === 19}
            />
          ))
        )}
      </Grid>
      <Message>
        {gameState === 'countdown' && `Starting in ${countdown}...`}
        {gameState === 'playing' && `Ship Position: (${shipPosition.x}, ${shipPosition.y})`}
        {gameState === 'gameover' && 'Game Over! Press any key to restart.'}
        {gameState === 'win' && 'You Win! Press any key to play again.'}
      </Message>
    </GameContainer>
  );
};

export default Game;
